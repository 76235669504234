import { useEffect, useRef, useState } from "react"
import { useRouter } from "next/router"
import {
  useSessionApi,
  useSessionDevice,
  useSessionEnvironment,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import { debug } from "@hornet-web-react/core/utils"
import GoogleAnalyticsService from "@hornet-web-react/core/services/GoogleAnalyticsService"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { SuccessfulLoginPayload } from "@hornet-web-react/core/services/LoginService"
import { LoggedInUser } from "@hornet-web-react/core/types/session"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { UseAppStartEmitter } from "./use-app-start"

export const useSessionUserLogin = (mutateSession: () => Promise<unknown>) => {
  const { currentAppUrl } = useSessionEnvironment()
  const { locale } = useSessionDevice()
  const { isAuthenticated, currentUser } = useSessionUser()
  const {
    user: { storeUser, logout },
  } = useSessionApi()
  const apiService = useCoreService<ApiService>(CORE_TYPES.ApiService)
  const googleAnalyticsService = useCoreService<GoogleAnalyticsService>(
    CORE_TYPES.GoogleAnalyticsService
  )
  const [hasAuthError, setHasAuthError] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  // for the correct login call, we need correct `currentAppUrl` and that gets set in the AppStart
  // so we have to wait for that, and then we can attempt the login
  const [hasAppStarted, setHasAppStarted] = useState(false)

  // this makes sure the login only happens once
  const hasAttemptedLogin = useRef(false)

  useEffect(() => {
    const changeAppStartDoneChangedHandler = (appStartDone: boolean) => {
      if (appStartDone) {
        setHasAppStarted(true)
      }
    }

    UseAppStartEmitter.on(
      "appStartDoneChanged",
      changeAppStartDoneChangedHandler
    )

    return () => {
      UseAppStartEmitter.off(
        "appStartDoneChanged",
        changeAppStartDoneChangedHandler
      )
    }
  }, [])

  const router = useRouter()

  // this effect is unnecessary *when SSG without OTP* if the session is set
  // via cookie in `useAppStart` - but this effect doesn't "wait" for it,
  // so in this (edge) case there is this unnecessary call and re-render

  useEffect(() => {
    if (hasAttemptedLogin.current || !hasAppStarted) {
      return
    }

    const searchParams = new URLSearchParams(window.location.search)
    const otp = searchParams.get("otp")

    if (!otp && isAuthenticated && currentUser?.profileId) {
      googleAnalyticsService.setDimensions({
        USER_PROFILE_ID: currentUser.profileId,
      })

      hasAttemptedLogin.current = true
      setHasAuthError(false)
      return
    }

    setIsLoading(true)

    searchParams.delete("otp")
    searchParams.set("locale", locale)

    const { abortController, apiRequest } =
      apiService.useAbortableEndpoint<SuccessfulLoginPayload>(
        ApiService.getEndpoint(ApiServiceEndpoint.ApiLoginPost, [
          currentAppUrl,
          searchParams.toString(),
        ]),
        { otp }
      )

    apiRequest()
      .then((successPayload: SuccessfulLoginPayload): void => {
        setIsLoading(false)
        setHasAuthError(false)

        storeUser(LoggedInUser.parse(successPayload))

        void mutateSession()

        googleAnalyticsService.setDimensions({
          USER_PROFILE_ID: successPayload.currentUser?.profileId,
        })

        // clean up the OTP from the URL
        // WARN: this most likely does not work in webview
        if (otp) {
          searchParams.delete("otp")

          router.push(
            `${window.location.pathname}?${searchParams.toString()}`,
            undefined,
            {
              shallow: true,
            }
          )
        }

        hasAttemptedLogin.current = true
      })
      .catch(() => {
        setIsLoading(false)

        // everything works as expected in this case
        // no need for other error tracking etc
        if (abortController.signal.aborted) {
          return
        }

        // no dangling previous cookies etc
        // logout()

        setHasAuthError(true)
        hasAttemptedLogin.current = true
      })

    return () => {
      abortController.abort()
    }
    // On purpose, only run once when app has started
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAppStarted])

  debug(`useSessionUserLogin: hasAuthError: ${hasAuthError ? "yes" : "no"}`)

  return { hasAuthError, isLoading, isLoggedIn: hasAuthError === false }
}
